import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-payer-filter',
    templateUrl: './payer-filter.component.html',
})
export class PayerFilterComponent {
    @Input() payerFilter: string;
    @Output() payerFilterChange = new EventEmitter<string>();

    payerOptions = [
        {id: 'HOSPICE', label: 'Hospice'},
        {id: 'LTC', label: 'LTC'},
        {id: 'SKILLED', label: 'Skilled'},
    ];
    selectedPayer: string;

    onPayerFilterChange(selected) {
        this.payerFilterChange.emit(selected);
    }
}
